<svelte:options tag="most-clicked" />

<script>
  import { onMount } from "svelte";

  let rows = [];
  let isLoaded = false;

  onMount(async () => {
    rows = await getCounter();
    isLoaded = true;
  });

  async function getCounter() {
    let url = "https://servizi.aequaroma.it/pio/api/public/cms/counter/findAll";
    let body = {
      pageSize: 4,
      pageNumber: 1,
      sort: {},
    };
    body.sort["counter"] = -1;

    const rs = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body || {}),
    });
    const json = await rs.json();
    return json?.rows;
  }

</script>

{#if isLoaded}
  <div class="my-row row" style="justify-content: center !important;">
    {#each rows as row}
      <div class="my-3 hover col-12 col-md-6 col-lg-3">
        <a href={row?.additionalProperties?.slug}>
          <div class="cardDefault">
            <img alt={row?.additionalProperties?.title} class="card-img-top" src={row?.additionalProperties?.thumbnail} />
            <div class="p-3">
              <h5 class="card-title">{row?.additionalProperties?.title}</h5>
              <p class="card-body">{row?.additionalProperties?.abstract}</p>
            </div>
          </div>
        </a>
      </div>
    {/each}
  </div>
{/if}

<style>
  .cardDefault {
    background-color: #fff;
    background-clip: border-box;
    background-size: cover;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    box-shadow: 5px 7px 8px 4px #ccc;
    word-wrap: break-word;
    transition: all 250ms ease-in-out;
    min-height: 375px;
    margin: auto;
    padding: 10px;
    max-width: 20em;
  }

  .card-img-top {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .card-title {
    font-size: 20px;
    font-weight: 600 !important;
  }

  .card-body {
    font-size: 15px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .hover:hover {
    transform: scale(105%);
  }

  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  @media (min-width: 768px), (max-width: 992px) {
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  @media (min-width: 992px) {
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
  }

  @media (min-width: 1520px) {
    .my-row {
      flex-wrap: nowrap;
    }
  }

  @media (max-width: 767px) {
    .col-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
</style>
